import React from 'react'
import { Helmet } from 'react-helmet';
import './AdvertiserPage.css'
import imagePitchoff from '../../assets/images/image_pitchoff.png';
import InfoText from '../../components/layout/sections/InfoText';
import profileAlex from '../../assets/profile/profile_alex.png';
import profileBrian from '../../assets/profile/profile_brian.png'
import profileJeremy from '../../assets/profile/profile_jeremy.png'
import MarqueeBackground from '../../components/layout/animator/MarqueeBackground';
import TestimonialCarousel from "../../components/common/lists/TestimonialCarousel";
import VerticalText from '../../components/layout/sections/VerticalText';
import imageCoverA from '../../assets/images/image_cover_a.png';
import imagePlatformB from '../../assets/images/image_platform_b.png';
import imageRiderG from '../../assets/images/image_rider_g.jpg';
import gifTarget from '../../assets/gif_target.gif'
import gifSpeedometer from '../../assets/gif_speedometer.gif'
import gifLightBulb from '../../assets/gif_light_bulb.gif'

export default function AdvertiserPage() {

    const infoTextData = [
        {
            title: "WANT MORE?",
        },
        {
            button: "RECOGNITION",
            text: "We’re glowing! Check out the awards and shoutouts we’ve earned for shaking up the ad world.",
            href: "/construction",
        },
        {
            button: "TESTMONIALS",
            text: "Hear firsthand from clients about what it’s like to work with MetroMesh and our cutting-edge solutions.",
            href: "/construction",
        },
        {
            button: "PRESS",
            text: "From headlines to hashtags, get the scoop on all things MetroMesh making waves in the media.",
            href: "/construction",
        },
    ];

    const verticalTextData = [
        {
            title: "Dynamic Content Delivery",
            text: "Unlock actionable insights with MMM Ad Manager, tracking foot traffic, brand recall, and digital conversions. Gain valuable insights to enhance your advertising strategy, demonstrating tangible value at every stage of the consumer journey.",
            image: imageRiderG,
            icon: gifLightBulb,
            button: "LEARN MORE",
            href: "/construction",
        },
        {
            title: "Precision Hyper-Targeting",
            text: "With MetroMesh's Precision Targeting, connect with hard-to-reach audiences through geofenced campaigns tailored to ZIP codes, DMAs, competitive areas, and time slots, ensuring your message reaches the right people at the right time.",
            image: imageCoverA,
            icon: gifTarget,
            button: "LET'S TALK",
            href: "/construction",
        },
        {
            title: "In-depth Measurement & Attribution",
            text: "Experience the versatility of MMM Ad Manager as it serves high-definition, customizable advertisements triggered by real-time conditions such as location, weather, and live events. We bring your creative to life at street level.",
            image: imagePlatformB,
            icon: gifSpeedometer,
            button: "WORK WITH US",
            href: "/construction",
        },
    ];

    const testimonialsData = [
        {
            quote: "“This is a tech solution that combines the nature of delivery riders—constantly moving around the city, visible to just about anybody, uptown, downtown, east, west—they're everywhere; Impressive reachability.”",
            name: "ALEX SEHNAOUI, GLOBAL CHIEF GROWTH OFFICER",
            company: "R/GA",
            image: profileAlex,
        },
        {
            quote: "“Saying this media is OOH doesn’t reveal its secret sauce as much—I'd call it digital OOH. It’s unique. MetroMesh isn’t creating space in the usual way—even inside buildings, with some extra layers in there.”",
            name: "BRIAN SHIMMERLIK, CHIEF EXECUTIVE OFFICER",
            company: "VENGO",
            image: profileBrian,
        },
        {
            quote: "“Wouldn't you rather have a digital ad in the elevator with your customer than an ad on the back of a bus or a truck? MetroMesh is giving people a better option—and it's not just out-of-home; it's digital out-of-home.”",
            name: "JEREMY KAGAN, MANAGING PARTNER",
            company: "TEXTBOOK VENTURES",
            image: profileJeremy,
        },
    ];

    return (
        <div className='advertiserPage'>
            <Helmet>
                <title>Advertisers | MetroMesh Media</title>
            </Helmet>

            <MarqueeBackground title1={"We are MetroMesh,"} title2={"an DOOH Media"} subTitle1={"MetroMesh mission:"} subTitle2={"bring dynamic storytelling to every neighborhood"} subTitleClass={"heroSubTitleContainer"} marqueeText={"DELIVERY ADVERTISING"}>
                <img src={imagePitchoff} alt="Rider F" className='imageHeroRider' />
            </MarqueeBackground>
            <TestimonialCarousel testimonials={testimonialsData} text1={"Ready To "} text2={"Start A Campaign With Us?"} text3={"Let's"} text4={"Talk"} href1={"/construction"} href2={"mailto:jess@metromeshmedia.com"} />

            {/* <div className='advertiserPageContainer'>
                <p>NEW MEDIA TECHNOLOGY</p>
                <div className='advertiserBig'>Advertising Solutions</div>
                <div className='advertiserMiddle'>Street-level Digital Engagement</div>
                <div className='advertiserRegular'>Elevate your presence with MetroMesh's dynamic and data-driven advertising platform. Engage hyper-target audiences at the crucial moments, with content that adapts in real-time to factors like location, time, and weather. Harness the power of programmatic buying to ensure a measurable ROI, detailed attribution, and a truly optimized media investment.</div>
                <div className='advertiserBtnContainerThree'>
                    <div className="firstButtons">
                        <TextButton text={"Start Planning"} toWhere={'/signup'} type={'white'} />
                    </div>
                    <div className="secondaryButtons">
                        <TextButton text={"Case Studies"} toWhere={'/signup'} type={'black'} />
                        <TextButton text={"Insights"} toWhere={'/signup'} type={'black'} />
                    </div>
                </div>
            </div> */}
            <VerticalText verticalTextData={verticalTextData} />
            <InfoText infoTextData={infoTextData} />
        </div>
    )
}
