import React from 'react';
import './VideoPlayer.css';

const VideoPlayer = ({ videoSrc }) => {

    return (
        <video
            className="background-video"
            src={videoSrc}
            autoPlay
            loop
            muted
            playsInline
        />
    );
};

export default VideoPlayer;
