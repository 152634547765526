import React from 'react'

export default function ArrowButton({ text1, text2, style, href }) {
    return (
        <a class="arrowButton" style={style} href={href}>
            <span class="arrowButtonText">{text1} <span>{text2}</span></span>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30">
                <path d="M32.5 15.2L20.3 3.1l-.7.7 11.5 11.4-11.5 11.5.7.7z"></path>
                <path fill="#1e384b" d="M.4 14.7h30.8v1H.4z"></path>
            </svg>
        </a>
    )
}
