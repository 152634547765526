import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { Helmet } from 'react-helmet';
import ImageSlider from '../../components/common/lists/ImageSlider';
import profileJess from '../../assets/profile/profile_jess.png';
import profileJosh from '../../assets/profile/profile_josh.png'
import profileTom from '../../assets/profile/profile_tom.png'
import profileWinn from '../../assets/profile/profile_winn.png'
import imageAdweekNY from '../../assets/images/image_adweek_ny.png'
import imagePitchStage from '../../assets/images/image_pitch_stage.png'
import imagePitchoff from '../../assets/images/image_pitchoff.png'
import imagePitchIntro from '../../assets/images/image_pitch_intro.png'
import imageDeploymentGroup from '../../assets/images/image_deployment_group.jpg'
import imageDeploymentOne from '../../assets/images/image_deployment_one.jpg'
import imageDeploymentSetup from '../../assets/images/image_deployment_setup.jpg'
import logoAdweek2024 from '../../assets/logo/logo_adweek2024.png';
import gifBillboard from '../../assets/gif_billboard.gif';
import logoNYUeLab from '../../assets/logo/logo_nyu_elab.png';
import logoNewDevelopment from '../../assets/logo/logo_new_development.png';
import icon_check from '../../assets/icons/icon_check.svg';
import TestimonialCarousel from "../../components/common/lists/TestimonialCarousel";
import NewsSlider from '../../components/common/lists/NewsSlider';
import Marquee from '../../components/layout/animator/Marquee';
// import coverImageA from '../../assets/images/image_cover_a.png';
import coverImageB from '../../assets/images/image_cover_b.jpg';
import coverImageC from '../../assets/images/image_cover_c.jpg';
import coverImageD from '../../assets/images/image_cover_d.jpg';
import imagePlatformA from '../../assets/images/image_platform_a.jpg';
import imagePlatformB from '../../assets/images/image_platform_b.png';
import imagePlatformC from '../../assets/images/image_platform_c.png';
import mediaIntro from '../../assets/media/media_intro.gif';
import ArrowButton from '../../components/common/buttons/ArrowButton';
import InfoText from '../../components/layout/sections/InfoText';
import MarqueeBackground from '../../components/layout/animator/MarqueeBackground';

export default function HomePage() {
    const taglines = [
        "We ensure your message moves across town and arrives at every doorstep.",
        "With MetroMesh, your message moves wherever your audience does.",
        "We transform urban streets into vibrant, data-driven marketing channels."
    ];

    const testimonialsData = [
        {
            quote: "“Your brand deserves more than visibility—it deserves relevance. MetroMesh Media ensures your message meets people where they are, not where you hope they’ll be.”",
            name: "JESS HSU, MARKETING & SDR",
            company: "METROMESH MEDIA",
            image: profileJess,
        },
        {
            quote: "“Imagine your brand moving through the busiest streets, reaching thousands of eyes daily—our delivery riders take your message directly to your audience, right where they live, work, and shop.”",
            name: "JOSH HSU, GROWTH & SDR",
            company: "METROMESH MEDIA",
            image: profileJosh,
        },
        {
            quote: "“At MetroMesh, we’re not just creating advertising—we’re creating experiences. Our solutions turn every ride into an opportunity, making sure your brand resonates with real people in real-time.”",
            name: "TOM HSU, GROWTH & STRATEGY",
            company: "METROMESH MEDIA",
            image: profileTom,
        },
    ];

    const newsSliderData = [
        {
            titleLogo: logoAdweek2024,
            text: "MetroMesh Media at Advertising Week New York 2024",
            button: "GET THE LATEST NEWS",
            imageTop: gifBillboard,
            imageMid: profileWinn,
            imageBot: imageAdweekNY,
            href: "/construction",
        },
        {
            titleLogo: logoNewDevelopment,
            text: "MetroMesh Media Launches New Location in Washington Square",
            button: "EXPLORE NEW SPACES",
            imageTop: imageDeploymentOne,
            imageMid: imageDeploymentGroup,
            imageBot: imageDeploymentSetup,
            href: "/construction",
        },
        {
            titleLogo: logoNYUeLab,
            text: "MetroMesh Media Shines at the 11th Annual NYU-Yale Pitchoff",
            button: "WATCH LIVE DEMO",
            imageTop: imagePitchStage,
            imageMid: imagePitchIntro,
            imageBot: imagePitchoff,
            href: "/construction",
        },
    ];

    const textArray = [
        "Illuminate Streets",
        "Empower Riders",
        "Drive ROI",
        "Lead With Innovation",
        "Stay Sustainable",
        "Maximize Impact",
        "Deliver Precision",
        "Expand Boundaries",
        "Own the Streets",
        "Transform Perception",
        "Break Barriers",
        "Hyper-Target Audiences",
        "Create Opportunities",
    ];

    const infoTextData = [
        {
            title: "LEADING THE WAY",
        },
        {
            button: "INSIGHTS",
            text: "Stay ahead with the freshest updates on digital marketing trends, cutting-edge research, and industry news that matters.",
            href: "/construction",
        },
        {
            button: "TECHNOLOGY",
            text: "Explore how MetroMesh empowers clients with advanced, AI-driven tools and innovative solutions to revolutionize urban advertising.",
            href: "/construction",
        },
        {
            button: "EXPERTS",
            text: "Get to know the visionaries driving your brand’s growth—a team dedicated to transforming your advertising strategy.",
            href: "/construction",
        },
    ];

    const typingSpeed = 40;
    const deletingSpeed = 20;
    const delayBetweenTaglines = 1000;

    const [displayedText, setDisplayedText] = useState("");
    const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const currentTagline = taglines[currentTaglineIndex];

        if (!isDeleting) {
            // Typing effect
            if (currentCharIndex < currentTagline.length) {
                setTimeout(() => {
                    setDisplayedText((prev) => prev + currentTagline.charAt(currentCharIndex));
                    setCurrentCharIndex((prev) => prev + 1);
                }, typingSpeed);
            } else {
                setTimeout(() => setIsDeleting(true), delayBetweenTaglines);
            }
        } else {
            // Deleting effect
            if (currentCharIndex > 0) {
                setTimeout(() => {
                    setDisplayedText((prev) => prev.slice(0, -1));
                    setCurrentCharIndex((prev) => prev - 1);
                }, deletingSpeed);
            } else {
                setIsDeleting(false);
                setCurrentTaglineIndex((prev) => (prev + 1) % taglines.length);
            }
        }
    }, [currentCharIndex, isDeleting, currentTaglineIndex, taglines]);

    return (
        <div>
            <div className='homePageContainer'>
                <Helmet>
                    <title>Home | MetroMesh Media</title>
                </Helmet>
                <MarqueeBackground title1={"Brightening Streets,"} title2={"Amplifying Brands"} subTitle1={"We only design one type of technology:"} subTitle2={"the kind that changes the narrative."} subTitleClass={"heroSubTitleContainer"} marqueeText={"SEEING IS BELIEVING"}>
                    <div className="typing-container">
                        <span className="text lora-500">{displayedText}</span>
                        <span className="cursor lora-500"></span>
                    </div>
                    <ImageSlider videoSrc={mediaIntro} imageSrcA={coverImageC} imageSrcB={coverImageB} imageSrcC={undefined} imageSrcD={coverImageD} type={'left'}>
                        <p className="lora-700 msgHeroBoxTitle">Creativity in Motion</p>
                        <p className='lora-400 msgHeroBoxText'>MetroMesh team thrives on uncovering hidden opportunities. With the one-and-only delivery ad networks, we help your brand break through the clutter with precision, creativity, and efficiency. We are not just rethinking advertising; we are redefining it.</p>
                        <p className="lora-500 msgHeroBoxSubTitle">Your brand will reach the streets, the people, and every doorstep with precision.</p>
                    </ImageSlider>
                </MarqueeBackground>
                <div className="homeTextBanner">
                    <div className="columnContainer">
                        <div>
                            <img src={icon_check} alt="icon_check" />
                            <p className='lora-500'>UNLEASH THE POTENTIAL</p>
                        </div>
                        <p className='lora-500'>It’s not about just placing ads—it’s about maximizing impact. We use advanced analytics and smart ad tech to optimize every impression and predict your next opportunity.</p>
                    </div>
                    <div className="columnContainer">
                        <div>
                            <img src={icon_check} alt="icon_check" />
                            <p className='lora-500'>SURPASS EXPECTATIONS</p>
                        </div>
                        <p className='lora-500'>We focus on real business outcomes, not vanity metrics. From driving immediate engagement to building sustained growth, MetroMesh ensures your brand achieves its full potential.</p>
                    </div>
                    <div className="columnContainer">
                        <div>
                            <img src={icon_check} alt="icon_check" />
                            <p className='lora-500'>TRANSFORM THE GAME</p>
                        </div>
                        <p className='lora-500'>Bold ideas redefine advertising. At MetroMesh, we’re committed to delivering what’s best for your brand, leveraging ingenuity over convention, and integrity over shortcuts.</p>
                    </div>
                </div>
                <TestimonialCarousel testimonials={testimonialsData} text1={"Ready To "} text2={"Ride Your Brand Further?"} text3={"GET IN TOUCH WITH"} href1={"/construction"} href2={"mailto:jess@metromeshmedia.com"} />
                <NewsSlider newsSliderData={newsSliderData} />
                <Marquee textArray={textArray} />
                <ImageSlider imageSrcA={imagePlatformA} imageSrcB={imagePlatformB} imageSrcC={imagePlatformC} type={'right'}>
                    <p className="lora-700 msgHeroBoxTitle">The Engine Behind Smarter Campaigns</p>
                    <p className="lora-400 msgHeroBoxText">MMM Ad Manager is our proprietary campaign management platform, designed to change the way you connect with audiences. With DataMesh, you build meaningful, measurable connections, one street at a time.</p>
                    <ArrowButton text1={"Explore Our Platform Today"} style={{ margin: '12px' }} href={"/construction"}/>
                </ImageSlider>
                <InfoText infoTextData={infoTextData} />
            </div>
        </div>

    )
}