import React from 'react'
import './Footer.css';
import logoWhite from '../../assets/logo/logo_white.png';
import logoLinkedin from '../../assets/logo/logo_linkedin.svg';
import logoYoutube from '../../assets/logo/logo_youtube.svg';
import UnderlineText from '../common/texts/UnderlineText';

export default function Footer() {
    return (
        <div style={{ backgroundColor: '#253746', padding: '24px 10vw', paddingTop: '0' }}>
            <div className='footer'>
                <div className='footerContent'>
                    <img src={logoWhite} alt="Logo White" className='logoHeight' />
                    <h1 className='middleTitle'>Deliver Real-Time, Real-Life Relevance</h1>
                    <div style={{ display: 'flex', flexDirection: 'row', color: '#bfbfbf', alignItems: 'center', fontSize: '14px', marginLeft: '-16px' }}>
                        <UnderlineText text={"Advertising T&C's"} classOption={'gray'} />
                        <span className='footerLine'>|</span>
                        <UnderlineText text={"Privacy Policy"} classOption={'gray'} />
                        <span className='footerLine'>|</span>
                        <UnderlineText text={"Partner Handbook"} classOption={'gray'} />
                    </div>
                </div>
                <span className='footerDivider' />
                <div className='footerNavLinks'>
                    <h2 href="" className='lora-500' >Get To Know Us</h2>
                    <a href="" className='lora-400'>Advertisers</a>
                    <a href="" className='lora-400'>Partners</a>
                    <a href="" className='lora-400'>Newsroom</a>
                    <a href="" className='lora-400'>About Us</a>
                </div>
                <span className='footerDivider' />
                <div className='footerSocialLinks'>
                    <h2 href="" className='lora-500' >Follow Us</h2>
                    <img src={logoLinkedin} />
                    <img src={logoYoutube} />
                </div>
                <span className='footerDivider' />
                <div className="contactinfo">
                    <h2 href="" className='lora-500' >Contact Us</h2>
                    <a href="tel:651.262.6027" className='lora-400'>
                        <svg width="25" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.9 17.7">
                            <title>Telephone</title>
                            <path d="M12.4 13.6c-2 0-3.5-1.5-3.5-3.3 0-1.8 1.6-3.3 3.5-3.3s3.5 1.5 3.5 3.3c0 1.8-1.6 3.3-3.5 3.3zm0-5.6C11 8 9.9 9 9.9 10.3c0 1.3 1.1 2.3 2.5 2.3s2.5-1 2.5-2.3C14.9 9 13.8 8 12.4 8z"></path>
                            <path d="M24.4 4.1c-.7-.6-4.5-3.7-12-3.7S1.1 3.5.4 4.1c-.1.1-.2.3-.1.5.4 2 1 3.2 1.6 4.2 0 .1.2.2.4.2 1 0 2-.2 3-.4l-2.4 3.6c-.1.1-.1.2-.1.3v4c0 .3.2.5.5.5h18.2c.3 0 .5-.2.5-.5v-4c0-.1 0-.2-.1-.3l-2.4-3.6c1 .2 2.1.4 3 .4.2 0 .3-.1.4-.3.6-1 1.2-2.3 1.6-4.2.1-.1 0-.3-.1-.4zM18 8.3l2.9 4.4v3.4H3.8v-3.4l2.9-4.4v-.1c.3-.1.6-.1.8-.2.2-.1.3-.3.3-.5V5.3c.6-.3 2.1-.8 4.6-.8s4 .6 4.5.8v2.2c0 .2.1.4.3.5.3.1.6.1.8.3 0-.1 0 0 0 0zm4.2-.3c-1.4-.1-3-.4-4.3-.8V5c0-.2-.1-.3-.3-.4-.3-.2-2-1.1-5.3-1.1s-5 .9-5.3 1.1c-.1 0-.2.2-.2.4v2.2c-1.2.4-2.8.7-4.2.8-.5-.8-1-1.9-1.3-3.3.9-.8 4.5-3.2 11.1-3.2 6.5 0 10.1 2.5 11.1 3.2-.3 1.4-.8 2.5-1.3 3.3z" ></path>
                        </svg>
                        651.262.6027
                    </a>
                    <a href="mailto:jess@metromeshmedia.com" className='lora-400'>
                        <svg width="25" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.9 17.7">
                            <title>Contact</title>
                            <path
                                d="M23.7 1.7c-.1 0-.1 0 0 0-.4-.5-1-.9-1.7-.9H3.3c-.7 0-1.3.4-1.7.9-.3.4-.4.8-.4 1.2v11.5c0 .6.3 1.1.7 1.5.4.3.9.6 1.4.6h18.6c.6 0 1.1-.2 1.4-.6.4-.4.7-.9.7-1.5V2.9c.1-.4-.1-.8-.3-1.2zm-.6 1.2v11.5c0 .2 0 .3-.1.5l-6.2-6.5 6.3-5.7v.2zm-1.2-1.2c.3 0 .5.1.7.2l-9.8 8.9c-.1.1-.3.1-.4 0L2.6 2c.2-.2.4-.3.7-.3h18.6zM2.2 14.9c-.1-.1-.1-.3-.1-.5V2.9v-.2l6.3 5.7-6.2 6.5zm1.1.7c-.2 0-.3 0-.4-.1L9.1 9l2.7 2.5c.2.2.5.3.8.3.3 0 .6-.1.8-.3L16.1 9l6.2 6.5c-.1.1-.3.1-.4.1H3.3z"
                            ></path>
                        </svg>
                        jess@metromeshmedia.com
                    </a>
                </div>
            </div>
            <p className='copyRightMMM'>MetroMesh Media © 2024. All Rights Reserved.</p>
        </div>
    )
}
