import React from "react";
import "./Animators.css";

const SliderAnimator = ({ children, animateDirection, sliderStyleClass, onAnimationEnd }) => {

    return (
        <div className={`${animateDirection} ${sliderStyleClass}`} onAnimationEnd={onAnimationEnd}>
            {children}
        </div>
    );
};

export default SliderAnimator;
