import React from 'react'
import TextButton from '../../components/common/buttons/TextButton'
import './AboutPage.css'
import lednight from '../../assets/images/image_led_night.png';
import image_city_g from '../../assets/images/image_city_g.jpg';
import { Helmet } from 'react-helmet';

export default function AboutPage() {
    return (
        <div className='aboutPageContainer'>
            <Helmet>
                <title>About Us | MetroMesh Media</title>
            </Helmet>
            <div>
                <p>METROMESH MEDIA</p>
                <p className='aboutBigTitle aboutSmallIntval'>Getting to Know Us</p>
            </div>
            <div className='aboutIntroflexRow aboutSmallIntval'>
                <img src={lednight} alt="LED Night" />
                <div className='rightText'>
                    <div className='aboutMiddleTitle aboutSmallIntval'>MetroMesh is the street-level digital media platform that leverages delivery network to connect audiences with dynamic media.</div>
                    <div className='aboutBtn'>
                        <TextButton text={"Connect Audiences"} toWhere={'/signup'} type={'black'} />
                    </div>
                </div>
            </div>
            <div>
                <div className='aboutflexRow'>
                    <h2 className='aboutBigTitle'>Our Mission</h2>
                    <TextButton text={"Join Our Team"} toWhere={'/construction'} type={'white'} />
                </div>
                <p className='aboutMiddleTitle aboutBigIntval'>Empowering Innovation, Connecting Communities</p>
                <p className='aboutRegularText aboutBigIntval'>At MetroMesh, our mission is to harness the power of technology to bring people together and foster innovation. We believe in creating solutions that not only solve today's challenges but also anticipate the needs of tomorrow. Our dedication to excellence and innovation drives us to continuously improve and push boundaries, aiming to impact society positively and build a more connected world.</p>
                <div className='aboutIntroflexRow aboutFlexIntval'>
                    <div className='aboutleftContainer'>
                        <div>
                            <p>Cutting-edge Technology</p>
                            <h1>Leveraging the latest advancements to offer superior products and services, we constantly push the envelope to bring our customers the most advanced solutions.</h1>
                        </div>
                        <div>
                            <p>Sustainability Commitment</p>
                            <h1>Prioritizing eco-friendly practices and products to protect our planet for future generations, our commitment extends beyond compliance to embrace proactive measures that contribute to a sustainable future.</h1>
                        </div>
                        <div>
                            <p>Industry Leadership</p>
                            <h1>Recognized as pioneers, we continue to lead by example, inspiring the industry through our commitment to excellence, ethical practices, and the pursuit of creating meaningful impact.</h1>
                        </div>
                    </div>
                    <div className='aboutrightContainer'>
                        <div>
                            <p>Customer-centric Approach</p>
                            <h1>Tailoring solutions to meet the unique needs of each customer, ensuring satisfaction and success, we place immense value on understanding our customers' needs, crafting bespoke solutions that not only solve current challenges but also pave the way for future opportunities.</h1>
                        </div>
                        <div>
                            <p>Global Community</p>
                            <h1>Building a diverse and inclusive community where ideas flourish and collaboration leads to success, we cherish and nurture the varied perspectives and experiences within our team, believing that our collective diversity is our greatest strength and the key to our innovative spirit.</h1>
                        </div>
                    </div>
                </div>
                <div className='aboutIntroflexRow aboutFlexIntval'>
                    <img src={image_city_g} alt="City G" />
                    <div className='rightText aboutBigIntval'>
                        <h1>MetroMesh was born from a simple idea: to use technology to connect people and ideas. Founded in 2023 in Manhattan by a group of enthusiasts who believed in making a difference, MetroMesh quickly evolved from a concept into an innovative force in media services. Along the way, we faced challenges, from technological hurdles to scaling our operations, but our passion and dedication turned obstacles into opportunities for growth. Today, MetroMesh stands as a testament to the impact of innovation and the power of entrepreneurship.</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}
