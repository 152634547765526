import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './Buttons.css';

export default function TextButton({ text, color, colorOpposite, width, padding, href }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <a
            className="textButtonContainer lora-500"
            style={{
                width: width,
                padding: padding,
                color: isHovered ? colorOpposite : color,
                backgroundColor: isHovered ? color : colorOpposite,
                borderColor: isHovered ? color : 'transparent',
                textDecoration: 'none'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            href={href}
        >
            {text}
        </a>
    );
}