import React, { useState, useEffect } from 'react';
import './ConstructionPage.css';
import backgroundBag from '../../assets/images/image_bag.jpg';
import backgroundCoverA from '../../assets/images/image_cover_a.png';
import backgroundPlatformB from '../../assets/images/image_platform_b.png';
import InfoText from '../../components/layout/sections/InfoText';
import ImageSlider from '../../components/common/lists/ImageSlider';
import ArrowButton from '../../components/common/buttons/ArrowButton';

export default function ConstructionPage() {
    const [progress, setProgress] = useState(0);
    const infoTextData = [
        {
            title: "WANT MORE?",
        },
        {
            button: "RECOGNITION",
            text: "We’re glowing! Check out the awards and shoutouts we’ve earned for shaking up the ad world.",
            href: "/construction",
        },
        {
            button: "TESTMONIALS",
            text: "Hear firsthand from clients about what it’s like to work with MetroMesh and our cutting-edge solutions.",
            href: "/construction",
        },
        {
            button: "PRESS",
            text: "From headlines to hashtags, get the scoop on all things MetroMesh making waves in the media.",
            href: "/construction",
        },
    ];

    useEffect(() => {
        const target = 93;
        const intervalTime = 30;
        const totalIntervals = 3000 / intervalTime;
        const increment = target / totalIntervals;

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const updatedProgress = prevProgress + increment;
                if (updatedProgress >= target) {
                    clearInterval(interval);
                    return target;
                }
                return updatedProgress;
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="construction-page" >
            <ImageSlider imageSrcA={backgroundBag} imageSrcB={backgroundCoverA} imageSrcC={backgroundPlatformB} type={'right'}>
                <p className="lora-700 msgHeroBoxTitle">LOST IN TRANSIT!</p>
                <p className="lora-400 msgHeroBoxText">Oops! Looks like this page has gone rogue! Don’t worry—our delivery riders never miss a destination.</p>
                <div className='progress-text lora-500'>
                    <p>Progress:</p>
                    <p>{Math.round(progress)}%</p>
                </div>
                <ArrowButton text1={"Get Back on track"} style={{ margin: '12px' }} href={"/"} />
            </ImageSlider>
            <InfoText infoTextData={infoTextData} />
        </div>
    );
}
