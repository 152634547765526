import React from "react";
import "./Animators.css";
import ImageDivider from "../../common/elements/ImageDivider";

const Marquee = ({ textArray }) => {
    return (
        <div className="marquee-container">
            <div className="marquee-content">
                {/* Duplicate the content for seamless scrolling */}
                {textArray.concat(textArray).map((text, index) => (
                    <span key={index} className="marquee-item lora-500">
                        {text}
                        <ImageDivider sliderStyleClass={"imgDivider"} />
                    </span>
                ))}
            </div>
        </div>
    );
};

export default Marquee;