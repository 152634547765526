import React from 'react'

export default function MarqueeBackground({ children, title1, title2, subTitle1, subTitle2, subTitleClass, marqueeText }) {
    return (
        <div className='heroContainer' >
            <div className="heroMarqueeContainer">
                <p className="heroMarqueeText lora-700">
                    {marqueeText}
                </p>
            </div>
            <p className='heroTitle lora-700' style={{ zIndex: '0' }}>{title1}<br></br>{title2}</p>
            <div className={subTitleClass}>
                <p className='lora-500' style={{ textTransform: 'uppercase' }}>{subTitle1}</p>
                <p className='lora-500' style={{ textTransform: 'uppercase' }}>{subTitle2}</p>
            </div>
            {children}
        </div>
    )
}
