import React, { useState } from "react";
import "./Lists.css";
import ArrowButton from "../buttons/ArrowButton";
import SliderAnimator from "../../layout/animator/SliderAnimator";
import ImageDivider from "../elements/ImageDivider";

const Carousel = ({ testimonials, text1, text2, text3, text4, href1, href2 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateDirection, setAnimateDirection] = useState("");

  const handleDotClick = (index) => {
    if (index === currentIndex) return;
    setAnimateDirection(index > currentIndex ? "go-away-right" : "go-away-left");
    setTimeout(() => {
      setCurrentIndex(index);
      setAnimateDirection(index > currentIndex ? "come-in-right" : "come-in-left");
    }, 300);
  };

  return (
    <div className="carousel">
      <div style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: '100vw', padding: '64px 0' }}>
        <p className="lora-700" style={{ color: '#0d0d0d' }}>{text1}<a href={href1}>{text2}</a></p>
        <ImageDivider sliderStyleClass={"imgDivider"} />
        <ArrowButton text1={text3} text2={text4 === undefined ? testimonials[currentIndex].name.split(" ")[0] : text4} href={href2} />
      </div>


      <SliderAnimator animateDirection={animateDirection} sliderStyleClass={"carousel-content"} onAnimationEnd={() => setAnimateDirection("")}>
        <p className="carousel-quote lora-500-italic">{testimonials[currentIndex].quote}</p>
        <img
          src={testimonials[currentIndex].image}
          alt={testimonials[currentIndex].name}
          className="carousel-image"
        />
        <p className="carousel-name lora-500">{testimonials[currentIndex].name}</p>
        <p className="carousel-company lora-500">{testimonials[currentIndex].company}</p>
      </SliderAnimator>

      <div className="carousel-dots">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentIndex ? "active" : ""
              }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;