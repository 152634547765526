// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo/logo_white.png';
import './Header.css';
import closeIcon from '../../assets/icons/icon_close.svg';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    const isActive = (path) => {
        return location.pathname === path;
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [scrollWidth, setScrollWidth] = useState('0vw');

    // Scroll effect
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
            const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrolledPercentage = (window.scrollY / scrollHeight) * 100;
            setScrollWidth(`${scrolledPercentage}vw`);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const isPartnersPage = location.pathname === '/partners';

    return (
        <header
            className={`header ${isHovered || isScrolled ? 'hovered' : ''}`}
            style={{
                backgroundColor: isPartnersPage ? 'rgba(255, 255, 255, 0.2)' : '',
            }}
        >
            <Link className="logo" to="/">
                <div></div>
            </Link>
            <nav className={`navigation ${isOpen ? 'open' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="navigationPage">
                    <div className={isActive('/advertisers') ? 'active' : ''}>
                        <Link className="lora-400" to="/advertisers" onClick={handleLinkClick}>
                            <div>Advertisers</div>
                        </Link>
                    </div>
                    <div className={isActive('/partners') ? 'active' : ''}>
                        <Link className="lora-400" to="/partners" onClick={handleLinkClick}>
                            <div>Partners</div>
                        </Link>
                    </div>
                    <div className={isActive('/newsroom') ? 'active' : ''}>
                        <Link className="lora-400" to="/construction" onClick={handleLinkClick}>
                            <div>Newsroom</div>
                        </Link>
                    </div>
                    <div className={isActive('/about') ? 'active' : ''}>
                        <Link className="lora-400" to="/construction" onClick={handleLinkClick}>
                            <div>About Us</div>
                        </Link>
                    </div>
                    <div className={isActive('/contact') ? 'active' : ''}>
                        <Link className="lora-400 contact" to="/construction" onClick={handleLinkClick}>
                            <div>Contact</div>
                        </Link>
                    </div>
                    <i onClick={toggleMenu} class="siteheaderMenu"></i>
                </div>
            </nav>
            <div className="headerBottomBorder" style={{ width: scrollWidth, transition: 'width 0.7s ease' }}></div>
        </header>
    );
};

export default Header;
