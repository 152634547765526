import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import './PartnerPage.css'
import imageRiderF from '../../assets/images/image_rider_f.jpg';
import imageDeploymentGroup from '../../assets/images/image_deployment_group.jpg';
import heroGifPartners from '../../assets/gif_partners.gif';
import TextFAQs from '../../components/layout/sections/TextFAQs';
import icon_check from '../../assets/icons/icon_check.svg';
import InfoText from '../../components/layout/sections/InfoText'; 

export default function PartnerPage() {
    const images = [heroGifPartners, imageDeploymentGroup, imageRiderF];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState(false);

    const infoTextData = [
        {
            "title": "DELIVERING OPPORTUNITIES"
        },
        {
            "button": "EARN MORE",
            "text": "Boost your income by turning your delivery routes into high-impact advertising opportunities with MetroMesh.",
            "href": "/construction"
        },
        {
            "button": "SMART GEAR",
            "text": "Carry beautiful, lightweight advertising tech designed to maximize visibility while keeping you comfortable.",
            "href": "/construction"
        },
        {
            "button": "JOIN NOW",
            "text": "Become part of an exclusive network of top riders and start earning effortlessly with MetroMesh.",
            "href": "/construction"
        },
    ];


    const faqData = [
        {
            question: "How do I qualify to become a MetroMesh rider?",
            answer: "You must be a full-time delivery rider, maintain nice gear, and interact positively with customers. You also need to responsibly handle MetroMesh’s advertising technology."
        },
        {
            question: "What type of bag will I need to carry?",
            answer: "You’ll use a lightweight, waterproof bag with a high-resolution LED display. It’s equipped with a GPS tracker, cellular module, and a long-lasting sustainable battery."
        },
        {
            question: "How much extra money can I earn?",
            answer: "Your income depends on the number of ads you carry, the area you cover, and the ad impressions you generate. MetroMesh riders earn extra income proportional to ad exposure."
        },
        {
            question: "Will carrying this advertising bag impact my deliveries?",
            answer: "No. The bag is designed to be lightweight and ergonomic. It won’t interfere with your delivery workflow."
        },
        {
            question: "How is my performance tracked?",
            answer: "Your ad performance is tracked using GPS and real-time analytics. The system calculates impressions based on location, time, and foot traffic data."
        },
        {
            question: "Do I need to manage the ads myself?",
            answer: "No. Ads are pre-programmed and managed remotely by MetroMesh. Your role is simply to carry the bag responsibly."
        },
        {
            question: "What if my bag gets damaged?",
            answer: "MetroMesh provides support for repairing or replacing damaged advertising equipment, provided the damage wasn’t due to negligence."
        },
        {
            question: "Are there any costs for participating?",
            answer: "There are no upfront costs for joining the program. MetroMesh provides the bag at no cost to qualified riders."
        },
        {
            question: "Can I customize or modify the advertising bag?",
            answer: "No. Modifying the advertising bag is not allowed to ensure compliance with MetroMesh standards and functionality."
        },
        {
            question: "How do I apply and get started?",
            answer: "You can apply online via MetroMesh’s website or contact the team directly. Once approved, you’ll receive your advertising bag and training to begin."
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(true); // Trigger fade out
            setTimeout(() => {
                // Update the image after fade out is complete
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFade(false); // Trigger fade in
            }, 1500); // Match the fade-out duration
        }, 7000); // Change image every 7 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);

    return (
        <div className='partnerPage'>
            <Helmet>
                <title>Partners | MetroMesh Media</title>
            </Helmet>
            <img src={images[currentImageIndex]} alt="Partners" className={`heroPartners ${fade ? 'fade' : ''}`} />
            <div className="homeTextBanner">
                <div className="columnContainer">
                    <div>
                        <img src={icon_check} alt="icon_check" />
                        <p className='lora-500'>EXTRA INCOME</p>
                    </div>
                    <p className='lora-500'>Advertise with MetroMesh and grow your business with <span className='lora-700' style={{ color: '#2d3284' }}>highly profitable, recurring cash flow.</span></p>
                </div>
                <div className="columnContainer">
                    <div>
                        <img src={icon_check} alt="icon_check" />
                        <p className='lora-500'>STABLE CASH</p>
                    </div>
                    <p className='lora-500'>We give you a new, beautiful advertising bag and <span className='lora-700' style={{ color: '#2d3284' }}>pay you at the end of each week.</span></p>
                </div>
                <div className="columnContainer">
                    <div>
                        <img src={icon_check} alt="icon_check" />
                        <p className='lora-500'>SIMPLY DRIVE</p>
                    </div>
                    <p className='lora-500'>We find value in the routes you already drive, meaning <span className='lora-700' style={{ color: '#2d3284' }}>we’ll never disrupt your existing operations.</span></p>
                </div>
            </div>
            <TextFAQs faqs={faqData} text={"We see your hustle, and we’re here to help you maximize every mile."}/>
            {/* <div className='partnerPageContainer'>
                <div className='flexRow'>
                    <h2 className='partnerBig'>Onboarding</h2>
                    <TextButton text={"Start Earning"} toWhere={'/contact'} type={'white'} />
                </div>
                <p className='partnerMiddle'>Earning as a JOCO Rider with MetroMesh</p>
                <p className='partnerRegular'>Onboarding with MetroMesh is a seamless process facilitated exclusively through our partner, JOCO. By joining, riders are equipped with our innovative digital advertising bags, making every delivery an earning opportunity without altering daily routine. The process, managed by JOCO, ensures a hassle-free integration, allowing riders to quickly join MetroMesh's advertising network and maximize their earnings. As a JOCO rider, you gain exclusive access to a new revenue stream, where riding, delivering, and earning converge effortlessly. With JOCO handling the installation and management of the digital displays, riders can focus on delivering while boosting their income. Embark on a journey with JOCO today and transform your delivery rides into lucrative ventures with MetroMesh.</p>
                <div className='introflexRow'>
                    <div className='partnerLeftContainer'>
                        <div>
                            <p>Speed into Efficiency</p>
                            <h1>JOCO's fully electric bike fleet means you deliver faster, dodging city traffic with ease and ensuring timely deliveries, every time.</h1>
                        </div>
                        <div>
                            <p>The JOCO App</p>
                            <h1>Your delivery experience is in your hands with the JOCO app. From unlocking bikes to planning your route, every tool you need is just a tap away.</h1>
                        </div>
                        <div>
                            <p>Worry-Free Deliveries</p>
                            <h1>Secure your JOCO directly through the app in-between deliveries, giving you peace of mind as you move throughout the city.</h1>
                        </div>
                    </div>
                    <div className='partnerRightContainer introOutline'>
                        <div>
                            <p>Convenience at Every Turn</p>
                            <h1>With over 50 locations spread across New York City, there's always a JOCO station nearby, ready to kickstart your earnings journey. Seamless integration into your daily routes makes finding a JOCO for your next delivery as simple as stepping outside.</h1>
                        </div>
                        <div>
                            <p>Sustainability in Motion</p>
                            <h1>Choose JOCO and ride with purpose. Together, we're not just delivering goods; we're on a mission to significantly cut down carbon emissions, one delivery at a time. Join us in paving the way for a greener, cleaner Manhattan.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='imageMargin imageContainer'>
                <img src={imageDeploymentGroup} alt="Rider E" className='imageRiderE' />
                <div className='partnerWhiteBackground'>
                    <div className='partnerWhiteBackgroundText'>
                        <p>Now Available in New York City</p>
                        <h1>MetroMesh, together with JOCO, invites you to amplify your delivery earnings without extra hours on the clock. Our cutting-edge platform and easy-to-install digital displays offer a seamless way to increase your income.</h1>
                        <div>
                            <TextButton text={"Start Earning"} toWhere={'/contact'} type={'black'} />
                        </div>
                    </div>
                </div>
            </div> */}
            <InfoText infoTextData={infoTextData} />
        </div>
    )
}
