import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollTop from '../components/common/scroll/ScrollTop';
import HomePage from '../pages/HomePage/HomePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import AdvertiserPage from '../pages/AdvertiserPage/AdvertiserPage';
import PartnerPage from '../pages/PartnerPage/PartnerPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import ConstructionPage from '../pages/ConstructionPage/ConstructionPage';
import Header from '../components/layout/Header';
import Dashboard from '../pages/Dashboard/Dashboard';
import Footer from '../components/layout/Footer';
import BookingScreen from '../pages/BookingSystem/BookingScreen';
import BookingStep from '../pages/BookingSystem/BookingStep';
import SignupPage from '../pages/BookingSystem/SignupPage';
import FutureStep from '../pages/BookingSystem/FutureStep';
const AppRouter = () => {
    return (
        <Router>
            <div className='appImportant'>
                <Header />
                <ScrollTop />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/advertisers" element={<AdvertiserPage />} />
                    <Route path="/partners" element={<PartnerPage />} />
                    {/* <Route path="/contact" element={<ContactPage />} /> */}
                    {/* <Route path="/advertisers/dashboard" element={<Dashboard />} /> */}
                    {/* <Route path="/BookingScreen" element={<BookingScreen />} /> */}
                    {/* <Route path="/BookingStep" element={<BookingStep />} /> */}
                    {/* <Route path="/signup" element={<SignupPage />} /> */}
                    {/* <Route path="/FutureStep" element={<FutureStep />} /> */}
                    <Route path="/construction" element={<ConstructionPage />} />
                    <Route path="*" element={<ConstructionPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default AppRouter;