import React from 'react'
import "./Elements.css";

export default function ImageDivider({ sliderStyleClass }) {
    return (
        <div className={sliderStyleClass} data-scrolltrigger="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 120">
                <path d="M13.8 115H9L42 4.5h4.8z"></path>
            </svg>
        </div>
    )
}