import React from 'react'
import './VerticalText.css';
import TextButton from '../../common/buttons/TextButton';

export default function VerticalText({ verticalTextData }) {
    return (
        <div className='verticalTextContainer'>
            <div className='imageContainer'>
                <img src={verticalTextData[0].image} alt="image1" />
                <div >
                    <div style={{ flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                        {verticalTextData[0].icon && <img src={verticalTextData[0].icon} alt='icon1' />}
                        <h2>{verticalTextData[0].title}</h2>
                    </div>
                    <p>{verticalTextData[0].text}</p>
                    <TextButton text={verticalTextData[0].button} width={'160px'} padding={'8px 16px'} color={'#f2f2f2'} colorOpposite={'#253746'} href={verticalTextData[0].href} />
                </div>
            </div>
            <div className='imageContainer reverse'>
                <div >
                    <div style={{ flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                        {verticalTextData[1].icon && <img src={verticalTextData[1].icon} alt='icon1' />}
                        <h2>{verticalTextData[1].title}</h2>
                    </div>
                    <p>{verticalTextData[1].text}</p>
                    <TextButton text={verticalTextData[1].button} width={'160px'} padding={'8px 16px'} color={'#f2f2f2'} colorOpposite={'#253746'} href={verticalTextData[1].href} />
                </div>
                <img src={verticalTextData[1].image} alt="image2" />
            </div>
            <div className='imageContainer'>
                <img src={verticalTextData[2].image} alt="image3" />
                <div >
                    <div style={{ flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                        {verticalTextData[2].icon && <img src={verticalTextData[2].icon} alt='icon1' />}
                        <h2>{verticalTextData[2].title}</h2>
                    </div>
                    <p>{verticalTextData[2].text}</p>
                    <TextButton text={verticalTextData[2].button} width={'160px'} padding={'8px 16px'} color={'#f2f2f2'} colorOpposite={'#253746'} href={verticalTextData[2].href} />
                </div>
            </div>
        </div>
    )
}
