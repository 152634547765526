import React, { useState, useEffect } from 'react';
import './Lists.css';
import VideoPlayer from '../../layout/VideoPlayer';

const ImageSlider = ({ children, videoSrc, imageSrcA, imageSrcB, imageSrcC, imageSrcD, type, }) => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const content = [
    { type: 'video', src: videoSrc },
    { type: 'image', src: imageSrcA },
    { type: 'image', src: imageSrcB },
    { type: 'image', src: imageSrcC },
    { type: 'image', src: imageSrcD },
  ].filter((item) => item.src !== undefined);

  useEffect(() => {
    const timeoutDuration = content[currentContentIndex].type === 'video' ? 24000 : 6000;
    const timer = setTimeout(() => {
      setCurrentContentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }, timeoutDuration);
    return () => clearTimeout(timer);
  }, [currentContentIndex]);

  return (
    <div className={`content-container ${type}`}>
      <div className="msgHeroBox" >
        {children}
      </div>
      {/* {content[currentContentIndex].type === 'image' ?
        (<img src={content[currentContentIndex].src} alt="Displayed" style={{ objectPosition: 'center', objectFit: 'cover', backgroundColor: 'black' }} />)
        :
        (<VideoPlayer videoSrc={content[currentContentIndex].src} />)
      } */}
      <img src={content[currentContentIndex].src} alt="Displayed" style={{ objectPosition: 'center', objectFit: 'cover', backgroundColor: 'black' }} />
    </div>
  );
};

export default ImageSlider;