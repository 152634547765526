import React from 'react'
import TextButton from '../../common/buttons/TextButton'
import './InfoText.css';

export default function InfoText({ infoTextData }) {
    return (
        <div style={{ flexDirection: 'column' }}>
            <p className='lora-700 leadTheWayText'>{infoTextData[0].title}</p>
            <div className="infoTextBanner" style={{ paddingTop: '36px' }}>
                <div className="ctaColumnContainer">
                    <TextButton text={infoTextData[1].button} width={'160px'} padding={'8px 16px'} color={'#253746'} colorOpposite={'white'} href={infoTextData[1].href} />
                    <p className='lora-400'>{infoTextData[1].text}</p>
                </div>
                <div className="ctaColumnContainer">
                    <TextButton text={infoTextData[2].button} width={'160px'} padding={'8px 16px'} color={'#253746'} colorOpposite={'white'} href={infoTextData[2].href} />
                    <p className='lora-400'>{infoTextData[2].text}</p>
                </div>
                <div className="ctaColumnContainer">
                    <TextButton text={infoTextData[3].button} width={'160px'} padding={'8px 16px'} color={'#253746'} colorOpposite={'white'} href={infoTextData[3].href} />
                    <p className='lora-400'>{infoTextData[3].text}</p>
                </div>
            </div>
        </div>
    )
}
