import React, { useState } from 'react';
import './TextFAQs.css';

const TextFAQs = ({ faqs, text }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="faqs-section">
            <h2 className="faqs-title lora-700">Frequently Asked Questions</h2>
            <p className="faqs-description lora-500">{text}</p>
            <div className="faqs-list">
                {faqs.map((faq, index) => (
                    <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                        <div className="faq-header" onClick={() => toggleFAQ(index)}                >
                            <h3 className="faq-question lora-700">{faq.question}</h3>
                            <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        <div className="faq-body lora-400" style={{ maxHeight: activeIndex === index ? '200px' : '0' }}>
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TextFAQs;