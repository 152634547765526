import React, { useState } from "react";
import "./Lists.css";
import ArrowButton from "../buttons/ArrowButton";
import SliderAnimator from "../../layout/animator/SliderAnimator";

export default function NewsSlider({ newsSliderData }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animateDirection, setAnimateDirection] = useState("");
    const handleNext = () => {
        setAnimateDirection("go-away-right");
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === newsSliderData.length - 1 ? 0 : prevIndex + 1
            );
            setAnimateDirection("come-in-right");
        }, 300);
    };

    const handlePrev = () => {
        setAnimateDirection("go-away-left");
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? newsSliderData.length - 1 : prevIndex - 1
            );
            setAnimateDirection("come-in-left");
        }, 300);
    };

    return (
        <div className="news-slider" style={{ width: '100vw' }}>
            {/* Left Arrow */}
            <button className="news-slider-arrow" onClick={handlePrev} style={{ width: '5vw', padding: '0' }}>
                &#8249;
            </button>

            {/* Dynamic Slide Content */}
            <SliderAnimator animateDirection={animateDirection} sliderStyleClass={"news-slider-content"} onAnimationEnd={() => setAnimateDirection("")}>

                {/* <div className={`news-slider-content ${animateDirection}`} onAnimationEnd={() => setAnimateDirection("")}> */}
                <div className="news-slide active">
                    <div className="news-slider-left">
                        <img
                            src={newsSliderData[currentIndex].titleLogo}
                            alt="Title Logo"
                            className="news-slide-logo"
                        />
                        <p className="news-slide-text lora-700">
                            {newsSliderData[currentIndex].text}
                        </p>
                        <ArrowButton text1={newsSliderData[currentIndex].button} href={newsSliderData[currentIndex].href} />
                    </div>

                    <div className="news-slide-images">
                        <img
                            src={newsSliderData[currentIndex].imageTop}
                            alt="Top"
                            className="news-slide-image-top"
                        />
                        <img
                            src={newsSliderData[currentIndex].imageMid}
                            alt="Middle"
                            className="news-slide-image-mid"
                        />
                        <img
                            src={newsSliderData[currentIndex].imageBot}
                            alt="Bottom"
                            className="news-slide-image-bot"
                        />
                    </div>
                </div>
                {/* </div> */}
            </SliderAnimator>

            {/* Right Arrow */}
            <button className="news-slider-arrow" onClick={handleNext} style={{ width: '5vw', padding: '0' }}>
                &#8250;
            </button>
        </div>
    );
}
